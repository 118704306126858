import { Component, ComponentProps, onCleanup, onMount, Show } from 'solid-js';
import { Box, Flex, HopeProps } from '@hope-ui/solid';

import { CgSidebarOpen } from 'solid-icons/cg';

import { MenuButton, MenuStructure, generateMenus } from '../menu';
import { createLocalSignal } from '../../localstorage';
import DashboardBranding from './DashboardBranding';

export type DashboardSidebarProps = {
  menus?: MenuStructure[];
  onResize: () => void;
} & ComponentProps<'div'> &
  HopeProps;

export const DashboardSidebar: Component<DashboardSidebarProps> = (props) => {
  const [collapsed, setCollapsed] =
    createLocalSignal<boolean>('sidebarCollapsed');

  const toggleSidebar = () => {
    setCollapsed(!collapsed());
    props.onResize();
  };

  const viewportResizied = () => {
    props.onResize();
  };

  onMount(() => {
    window.addEventListener('resize', viewportResizied);
  });

  onCleanup(() => {
    window.removeEventListener('resize', viewportResizied);
  });

  return (
    <Flex
      pos='fixed'
      bg='$sidebarBackground'
      h='100vh'
      flexDirection='column'
      py='$6'
      px={collapsed() ? '$3' : '$6'}
      w={collapsed() ? 'auto' : '350px'}
      borderRightWidth='1px'
      borderRightColor='$sidebarBorder'
      css={{
        backdropFilter: 'blur(3px)',
      }}
      zIndex={1}
      // flexShrink={0}
      {...props}
    >
      <DashboardBranding collapsed={collapsed()} />

      <Box d='flex' flexDirection='column' gap='$3'>
        <Show when={props.menus}>
          {generateMenus(props.menus, collapsed())}
        </Show>
      </Box>

      <Box flexGrow={1} />

      <MenuButton
        onclick={toggleSidebar}
        icon={CgSidebarOpen}
        collapsed={collapsed()}
      >
        Collapse Menu
      </MenuButton>
    </Flex>
  );
};

export default DashboardSidebar;
