import { Component, Show, splitProps } from 'solid-js';
import { Box, Text } from '@hope-ui/solid';
import type { HopeProps } from '@hope-ui/solid';
import { Image } from '../global';
import GoldIcon from '../../assets/gw2/Gold_coin.png';
import SilverIcon from '../../assets/gw2/Silver_coin.png';
import CopperIcon from '../../assets/gw2/Copper_coin.png';

export type GW2CoinProps = {
  value: number;
  padded?: boolean;
} & HopeProps;

const Coin: Component<{
  amount: number;
  color: string;
  image: string;
  padded: boolean;
}> = (props) => (
  <Box d='flex' gap='$0_5' color={props.color} lineHeight='1.3em'>
    <span class='gwCoinVal'>
      {props.amount.toLocaleString(undefined, {
        minimumIntegerDigits: props.padded ? 2 : 1,
      })}
    </span>
    <Image h='1.5em' src={props.image} crossOrigin='anonymous' />
  </Box>
);

const GW2Coin: Component<GW2CoinProps> = (_props) => {
  const [local, props] = splitProps(_props, ['value']);

  const render = () => typeof local.value === 'number';
  const sign = () => (Math.sign(local.value) >= 0 ? '' : '-');
  const gold = () => Math.floor(Math.abs(local.value / 10000));
  const silver = () => Math.floor(Math.abs((local.value % 10000) / 100));
  const copper = () => Math.floor(Math.abs(local.value % 100));

  return (
    <Show when={render()}>
      <Box d='flex' alignItems='center' {...props}>
        <Text fontSize={props.fontSize} as='span' mt='-0.2rem'>
          {sign()}
        </Text>
        <Box d='flex' gap='$2' alignItems='center' fontSize={props.fontSize}>
          <Show when={gold() > 0}>
            <Coin
              amount={gold()}
              color='$gold'
              image={GoldIcon}
              padded={false}
            />
          </Show>
          <Show when={silver() > 0 || (props.padded && local.value >= 10000)}>
            <Coin
              amount={silver()}
              color='$silver'
              image={SilverIcon}
              padded={props.padded && local.value >= 10000}
            />
          </Show>
          <Show
            when={
              copper() > 0 ||
              (silver() === 0 && gold() === 0) ||
              (props.padded && local.value >= 100)
            }
          >
            <Coin
              amount={copper()}
              color='$copper'
              image={CopperIcon}
              padded={props.padded && local.value >= 100}
            />
          </Show>
        </Box>
      </Box>
    </Show>
  );
};

export default GW2Coin;
