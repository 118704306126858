import { Box, Heading, HopeProps, Text } from '@hope-ui/solid';
import { Component, Show, For, splitProps } from 'solid-js';

import { Image } from '../global';
import GW2Coin from '../gw2/GW2Coin';
import GW2Currency from '../gw2/GW2Currency';
import GW2Item from '../gw2/GW2Item';
import magicFindIcon from '../../assets/icons/gw2/magic-find.png';
import { SessionDropData } from '../../lib/drf/drop';

export type SessionEventProps = {
  event: SessionDropData;
} & HopeProps;

const SessionDrop = (props: { data: SessionDropData }) => {
  return (
    <Box
      d='flex'
      flexDirection={{ '@initial': 'column', '@lg': 'row' }}
      gap={{ '@initial': '$2', '@lg': '$8' }}
      justifyContent='space-between'
    >
      <Show when={props.data.items.length > 0}>
        <Box flex='0 1 auto'>
          <Box d='flex' gap='$2' mb='$1' alignItems='flex-end'>
            <Heading fontWeight='$normal' fontSize='$lg'>
              Item Drops
            </Heading>
            <Text
              d='flex'
              alignItems='center'
              gap='$1'
              color='$textMuted'
              fontSize='$sm'
            >
              <Image src={magicFindIcon} boxSize='$3' crossOrigin='anonymous'/>
              {props.data.magicFind}%
            </Text>
          </Box>
          <Box d='flex' flexWrap='wrap'>
            <For each={props.data.items}>
              {(item) => {
                return <GW2Item item={item} />;
              }}
            </For>
          </Box>
        </Box>
      </Show>
      <Show when={props.data.currencies.length > 0}>
        <Box flex='0 0 auto' w={{ '@initial': 'auto', '@lg': '$56' }}>
          <Heading fontWeight='$normal' fontSize='$lg'>
            Currencies
          </Heading>
          <Box>
            <For each={props.data.currencies}>
              {(currency) =>
                currency.id === 1 ? (
                  <GW2Coin value={currency.amount} />
                ) : (
                  <GW2Currency id={currency.id} amount={currency.amount} />
                )
              }
            </For>
          </Box>
        </Box>
      </Show>
    </Box>
  );
};

export const SessionEvent: Component<SessionEventProps> = (props) => {
  const [local, rest] = splitProps(props, ['event']);

  return (
    <Box
      d='flex'
      flexDirection='column'
      gap='$0_5'
      borderLeft='2px solid $container'
      pl='$2'
      {...rest}
    >
      <Box d='flex' gap='$2' alignItems='end'>
        <Text color='$accentBlue'>{local.event.character}</Text>
        <Text fontSize='$sm' color='$textMuted'>
          {new Date(local.event.timestamp).toLocaleTimeString(undefined, {
            hour12: false,
          })}
        </Text>
      </Box>
      <Box h='1px' bg='$container' pl='$2' ml='-$2' />
      {/* <Show when={local.event.kind === 'data'}> */}
      <SessionDrop data={local.event as SessionDropData} />
      {/* </Show> */}
    </Box>
  );
};
