import { GW2ApiItem } from 'gw2api-client';
import DropBuffer from './sync/dropbuffer';

import gw2api from '../gw2api';
import Gw2Worker from '../gw2worker';
// eslint-disable-next-line import/extensions
import BackupCurrencies from '../../assets/gw2/currencies.json';
// eslint-disable-next-line import/extensions
import BackupPriceIds from '../../assets/gw2/price_ids.json';

const priceIds: Promise<Set<number>> = gw2api
  .commerce()
  .prices()
  .ids()
  // eslint-disable-next-line import/extensions
  .catch(() => BackupPriceIds)
  .then((data: number[]) => new Set(data));
const currencies: Promise<any[]> = gw2api
  .currencies()
  .all()
  .catch(() => BackupCurrencies);

export default class DropFactory {
  private itemBuffer: DropBuffer;
  private characters: string[];
  private worker: Gw2Worker;

  constructor(buffer: DropBuffer, characters: string[], worker: Gw2Worker) {
    this.itemBuffer = buffer;
    this.setCharacters(characters);
    this.worker = worker;
  }

  setCharacters(characters: string[]) {
    this.characters = characters;
  }

  async createDropFromRange(
    start: number,
    end: number
  ): Promise<SessionDropData> {
    const data = this.emptyDrop(start);

    const promises = [];
    for (let i = start; i < end; i++) {
      promises.push(this.addDropData(data, i));
    }

    await Promise.allSettled(promises);
    return data;
  }

  static stripSessionDropData(data: SessionDropData): MarshalSessionDropData {
    return {
      character: data.character,
      currencies: data.currencies,
      magicFind: data.magicFind,
      timestamp: data.timestamp,
      items: data.items.map((i) => DropFactory.stripItemData(i)),
    };
  }

  async hydrateSessionDropData(
    data: MarshalSessionDropData[]
  ): Promise<SessionDropData[]> {
    return Promise.all(
      data.map(async (d) => ({
        character: d.character,
        currencies: d.currencies,
        magicFind: d.magicFind,
        timestamp: d.timestamp,
        items: await Promise.all(
          d.items.map(async (i) => this.hydrateItemData(i))
        ),
      }))
    );
  }

  emptyDrop(idx: number): SessionDropData {
    return {
      character: this.characters[Atomics.load(this.itemBuffer.dropChar, idx)],
      magicFind: Atomics.load(this.itemBuffer.dropMf, idx),
      timestamp: Number(Atomics.load(this.itemBuffer.dropTime, idx)),
      items: [],
      currencies: [],
    };
  }

  newDropData(idx: number): Promise<SessionDropData> {
    const data = this.emptyDrop(idx);

    return this.addDropData(data, idx);
  }

  async addDropData(data: SessionDropData, idx: number) {
    const itemId = Number(Atomics.load(this.itemBuffer.dropItem, idx));
    if (itemId !== 0) {
      data.items.push(await this.buildItemData(idx));
    }

    const currencyId = Number(Atomics.load(this.itemBuffer.dropCurr, idx));
    if (currencyId !== 0) {
      data.currencies.push(await this.buildCurrencyData(idx));
    }

    return data;
  }

  async buildItemData(index: number): Promise<AsyncItemData> {
    const id = Atomics.load(this.itemBuffer.dropItem, index);
    const { tp, api } = await this.fetchItemApiData(id);
    const data: AsyncItemData & DropItemOrigin = {
      id,
      amount: Number(Atomics.load(this.itemBuffer.dropCount, index)),
      tp,
      api,
      idx: index,
    };
    return data;
  }

  private async fetchItemApiData(id: number) {
    const api = this.worker.request('item', [id]).then((data) => data[0]);
    const commerceIds = await priceIds;
    let tp = Promise.resolve(null);
    if (commerceIds.has(id)) {
      tp = this.worker
        .request('price', [id])
        .then((values) =>
          values[0]
            ? {
                buy: values[0].buys.unit_price,
                sell: values[0].sells.unit_price,
              }
            : null
        )
        .catch(() => null);
    }
    return { tp, api };
  }

  static async awaitItemData(data: AsyncItemData): Promise<ItemData> {
    const res: ItemData = {
      tp: await data.tp,
      api: await data.api,
      ...DropFactory.stripItemData(data),
    };
    return res;
  }

  static stripItemData(data: AsyncItemData): DropItemBase {
    const res: DropItemBase & DropItemOrigin = {
      id: data.id,
      amount: data.amount,
      idx: (data as unknown as DropItemOrigin).idx,
    };
    return res;
  }

  async hydrateItemData(data: DropItemBase): Promise<AsyncItemData> {
    const { tp, api } = await this.fetchItemApiData(data.id);
    const res: AsyncItemData & DropItemOrigin = {
      id: data.id,
      amount: data.amount,
      api,
      tp,
      idx: (data as unknown as DropItemOrigin).idx,
    };
    return res;
  }

  async buildCurrencyData(index: number): Promise<CurrencyData> {
    const id = Number(Atomics.load(this.itemBuffer.dropCurr, index));
    const count = Number(Atomics.load(this.itemBuffer.dropCount, index));
    const api = await currencies;
    const apiObj = api.find((x) => x.id === id);
    const data: CurrencyData & DropItemOrigin = {
      id,
      amount: count,
      order: apiObj?.order,
      idx: index,
    };
    return data;
  }
}

export type SessionDropData = {
  character: string;
  items: AsyncItemData[];
  currencies: CurrencyData[];
  magicFind: number;
  timestamp: number;
};

export type MarshalSessionDropData = {
  character: string;
  items: DropItemBase[];
  currencies: CurrencyData[];
  magicFind: number;
  timestamp: number;
};

export type AsyncItemData = DropItemBase & {
  tp: Promise<{
    buy: number;
    sell: number;
  } | null>;
  api: Promise<GW2ApiItem>;
};

export type ItemData = DropItemBase & {
  tp: {
    buy: number;
    sell: number;
  } | null;
  api: GW2ApiItem;
};

export type CurrencyData = DropItemBase & {
  order: number;
};

export type DropItemOrigin = {
  idx: number;
};

export type DropItemBase = {
  id: number;
  amount: number;
};
